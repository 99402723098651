<template>
  <div class="text-center">
    <v-dialog
      :model-value="mainStore.showSharePost"
      maxWidth="400"
      :persistent="true"
      @click:outside="doCancel"
    >
      <v-card>
        <div class="px-6 pt-5">
          <button
            type="button"
            data-te-ripple-init
            data-te-ripple-color="light"
            style="border-radius: 50%"
            class="absolute right-4 top-4 inline-block rounded-full bg-beegup-red md:p-2 p-1.5 uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-beegup-red/80 focus:bg-beegup-red/60 focus:outline-none focus:ring-0 active:bg-beegup-red/70"
            @click.prevent="doCancel"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="md:w-5 md:h-5 w-4 h-4"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="#fff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M18 6l-12 12"/>
              <path d="M6 6l12 12"/>
            </svg>
          </button>
          <div class="flex flex-col gap-2">
            <div class="flex flex-col gap-2">
              <p class="md:text-base text-xs font-bold mb-0">{{ $t("LB_SHARE") }}</p>
            </div>
          </div>
        </div>

        <hr class="mt-6 mb-0 h-px border-0 bg-gray-200/90" >

        <div class="px-6">
          <template v-if="!isLoading">
            <div v-for="(user, index) of users" :key="user.id">
              <div
                :class="{
                'border-b border-beegup-light-grey': index !== users.length - 1,
              }"
              >
                <div class="flex items-center justify-between gap-2 py-4">
                  <div class="flex items-center space-x-8">
                    <input
                      type="checkbox"
                      class="h-4 w-4 cursor-pointer rounded border-gray-300 accent-beegup-blue text-white shadow-sm focus:ring focus:ring-primary-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400"
                      @click="userSelectCallback(user.uuid)"
                    >
                    <div class="flex items-center gap-2">
                      <div class="h-12 w-12 relative">
                        <img
                          class="h-full w-full rounded-full object-cover object-center"
                          :src="getUserImage(user)"
                          alt=""
                        >
                        <span
                          v-if="user.connected"
                          class="absolute right-0 top-0 md:h-4 md:w-4 h-3.5 w-3.5 rounded-full bg-green-400 ring ring-white"
                        />
                      </div>
                      <p class="md:text-sm text-xs font-bold mb-0">
                        {{ user.fullName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="isLoading"
            style="height: 400px; overflow: hidden; overflow-y: auto"
          >
            <p class="w-100 p-3 text-center">
              {{ $t("LB_LOADING") }}
            </p>
          </div>
        </div>

        <hr class="mt-0 mb-0 h-px border-0 bg-gray-200/90" >

        <div class="flex items-center justify-center py-6">
          <button
            type="button"
            class="rounded-lg bg-beegup-blue px-10 py-2.5 text-center text-sm font-medium text-white shadow-sm transition-all hover:bg-beegup-blue/80"
            @click="doValid"
          >
            {{ $t("LB_SHARE") }}
          </button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import {useMainStore} from "~/store";

const users = ref([]);
const isLoading = ref(true);

const mainStore = useMainStore();
const {showSharePost} = storeToRefs(mainStore);

watch(showSharePost, () => {
  users.value = [];
  if (showSharePost.value) {
    isLoading.value = true;
    const me = useNuxtApp().$auth.user();
    for (const user of me.follows) {
      users.value.push(
        {
          id: user.id,
          uuid: user.uuid,
          connected: user.connected,
          fullName: user.fullName,
          image: user.image,
          selected: false,
        }
      )
    }
    isLoading.value = false;
  }
})

const doCancel = () => {
  mainStore.setShowSharePost({ value: false, data: null });
};
const doValid = async () => {
  if (
    mainStore.sharePostData &&
    mainStore.sharePostData.callback
  ) {
    await mainStore.sharePostData.callback(
    users.value.filter((a) => a.selected === true)
  );
  }
  mainStore.setShowSharePost({ value: false, data: null });
};
const userSelectCallback = (userUuid) => {
  const userIndex = users.value.findIndex((a) => a.uuid === userUuid);
  if (userIndex >= 0) {
    users.value[userIndex].selected = !users.value[userIndex].selected;
  }
};
</script>
