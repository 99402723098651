<template>
  <v-dialog
    :model-value="$store.showExternalLinkDialog"
    :persistent="true"
    maxWidth="400"
    contentClass="alert-modal"
  >
    <v-card :key="$store.counter" style="border-top: 8px solid #00acdf">
      <v-card-title class="text-h5">
        {{ $t("LB_EXTERNAL_LINK") }}
      </v-card-title>
      <v-card-text class="p-0">
        <v-alert
          type="info"
          color="white"
          variant="flat"
          :prominent="true"
        >
          <p class="text-sm mb-0">
            {{ $t("MSG_EXTERNAL_LINK_WARNING") }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions class="pb-4 mr-3">
        <v-spacer />
        <v-btn variant="flat" @click="cancelAndClose">
          {{ $t("LB_CANCEL") }}
        </v-btn>
        <v-btn
          variant="flat"
          class="text-white"
          style="background: #00acdf"
          @click="openExternalLink"
        >
          {{ $t("LB_YES_UNDERSTOOD") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const openExternalLink = () => {
  window.open(useNuxtApp().$store.externalLinkUrl, "_blank");
  useNuxtApp().$store.acceptExternalLinksForSession();
  useNuxtApp().$store.closeExternalLinkDialog();
};
const cancelAndClose = () => {
  useNuxtApp().$store.closeExternalLinkDialog();
};
</script>

<style scoped>
.v-alert__border {
  border: 0;
}
</style>
