<template>
  <v-dialog
    :key="'call_modal_' + counter"
    :maxWidth="dialogWidth"
    height="100%"
    :model-value="$store.showCallModal === true"
    :persistent="true"
    class="call-modal-dialog"
  >
    <v-card
      v-if="
        activeDialog === 'RING' &&
        $store.callModalData
      "
    >
      <v-card-title
        id="card-title"
        class="text-h5 d-flex flex-col justify-center pb-4"
        style="padding: 2rem 3rem 0 !important"
      >
        <div class="flex items-center gap-4 mb-8">
          <svg
            v-if="callType === 'VIDEO'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              d="M3.25 4A2.25 2.25 0 001 6.25v7.5A2.25 2.25 0 003.25 16h7.5A2.25 2.25 0 0013 13.75v-7.5A2.25 2.25 0 0010.75 4h-7.5zM19 4.75a.75.75 0 00-1.28-.53l-3 3a.75.75 0 00-.22.53v4.5c0 .199.079.39.22.53l3 3a.75.75 0 001.28-.53V4.75z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              d="M8.25 4.5a3.75 3.75 0 117.5 0v8.25a3.75 3.75 0 11-7.5 0V4.5z"
            />
            <path
              d="M6 10.5a.75.75 0 01.75.75v1.5a5.25 5.25 0 1010.5 0v-1.5a.75.75 0 011.5 0v1.5a6.751 6.751 0 01-6 6.709v2.291h3a.75.75 0 010 1.5h-7.5a.75.75 0 010-1.5h3v-2.291a6.751 6.751 0 01-6-6.709v-1.5A.75.75 0 016 10.5z"
            />
          </svg>

          <p class="text-sm text-center font-extrabold mb-0">
            {{ callType === 'VIDEO' ? $t("LB_VIDEO_CALL") : $t("LB_AUDIO_CALL") }}
          </p>
        </div>
        <div class="d-flex pb-3">
          <div class="call-animation">
            <div class="content-center">
              <div class="pulse">
                <img
                  :src="getUserImage($store.callModalData.peerUser)"
                  height="28px"
                  style="margin: 0 auto"
                  width="28px"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <v-card-text
          class="text-h5 text-center pb-0 !py-0"
          style="
            padding-top: 0.5rem !important;
            padding-bottom: 0rem !important;
          "
        >
          <strong v-if="$store.callModalData.peerUser">
            {{ $store.callModalData.peerUser.fullName }}
          </strong>
        </v-card-text>
        <p
          v-if="direction === 'OUTGOING'"
          class="text-center text-uppercase"
          style="
            font-size: 10px;
            font-weight: 600;
            color: rgb(165 159 159);
            line-height: 2;
          "
        >
          {{ $t("CALL_IN_PROGRESS") }}
        </p>
        <p
          v-if="direction === 'INCOMING'"
          class="text-center text-uppercase"
          style="
            font-size: 10px;
            font-weight: 600;
            color: rgb(165 159 159);
            line-height: 2;
          "
        >
          {{ $t("LB_INCOMING_CALL") }}
        </p>
      </v-card-title>

      <v-card-actions
        class="d-flex justify-space-around px-0 py-8"
      >
        <v-btn color="red" icon xLarge @click="refuseCall">
          <img alt="" src="/images/videocall/end-call.svg" >
        </v-btn>
        <v-btn
          color="green"
          xLarge
          @click="acceptCall"
        >
          <img alt="" src="/images/videocall/accept-call.svg" >
        </v-btn>
      </v-card-actions>
    </v-card>
    <div
      v-if="
        activeDialog === 'CALL' &&
        $store.callModalData
      "
    >
      <div
        style="padding: 0 !important"
        class="card-video fullScreen"
      >
        <section
          class="section-container"
        >
          <div id="open_screen" class="fullscreen-media-container">
            <div class="partner-text-container">
              <span
                class="name text-capitalize"
              >
                {{ $store.callModalData.peerUser.fullName }}
              </span>
            </div>
            <video
              v-show="displayOtherVideoStream && callType === 'VIDEO'"
              id="otherVideoElm"
              ref="otherVideoElm"
              autoplay
              playsinline
              class="fullscreen-video"
              style="width: 100%;"
            />
            <div
              v-if="callType === 'AUDIO'"
              style="height: 550px; display: flex; align-items: center"
            >
              <img
                :src="getUserImage($store.callModalData.peerUser)"
                alt=""
                class="w-40 h-40 rounded-full m-auto object-cover"
              >
            </div>
            <div class="overlay-content-container d-md-flex d-sm-flex d-block">
              <div
                class="cam-container m-md-0 m-sm-auto m-auto order-2 mb-4 mb-md-0"
              >
                <div class="video">
                  <video
                    v-show="displayMyVideoStream && callType === 'VIDEO'"
                    ref="myVideoElm"
                    muted
                    playsinline
                  />
                  <div
                    v-if="callType === 'AUDIO'"
                    style="
                      display: flex;
                      align-items: center;
                      height: 100%;
                      overflow: hidden;
                      position: absolute;
                      width: 100%;
                    "
                  >
                    <img
                      :src="getUserImage($auth.user())"
                      alt=""
                      class="!w-16 !h-16 rounded-full m-auto object-cover"
                    >
                  </div>
                </div>
                <div class="content">
                  <i
                    class="fas fa-microphone-slash button_mic"
                    :class="{ '!hidden': !isMuted }"
                  />
                  <i
                    class="fas fa-video-slash button_mic"
                    :class="{ '!hidden': displayMyVideoStream }"
                  />
                  <span class="name">
                    {{ $t("LB_YOU") }}
                  </span>
                </div>
              </div>
              <div class="ui-container">
                <div class="navigation-controls-container m-auto m-md-0">
                  <button class="button-mic-element switch" @click="toggleMute">
                    <span class="icon">
                      <svg
                        width="12"
                        height="15"
                        viewBox="0 0 12 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          :class="{ active: !isMuted, inactive: isMuted }"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.95576 3.20547C8.95523 1.57343 7.63209 0.250528 6.00004 0.25C4.368 0.250488 3.04484 1.57367 3.04428 3.20571V6.88238C3.04469 8.16353 4.10998 9.25418 5.25 9.6642V11.1317C3.38532 10.682 1.75 9.00277 1.75 7H0.25C0.25 9.92145 2.42874 12.334 5.25 12.7015V13.5C5.25 13.9142 5.58579 14.25 6 14.25C6.41421 14.25 6.75 13.9142 6.75 13.5V12.7015C9.57126 12.334 11.75 9.92144 11.75 7H10.25C10.25 9.00277 8.61468 10.682 6.75 11.1317V9.66461C7.89054 9.25478 8.95607 8.16336 8.95576 6.88184V3.20547ZM7.45576 6.88236C7.45604 7.68593 6.80386 8.33758 6.00029 8.33786C5.19637 8.3376 4.54467 7.68578 4.54428 6.8819V3.20566C4.5447 2.40186 5.19624 1.75036 6.00004 1.75C6.80385 1.75039 7.45537 2.40215 7.45576 3.20596V6.88236Z"
                          fill="white"
                        />
                        <path
                          :class="{ active: isMuted, inactive: !isMuted }"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.00004 0.25C7.63209 0.250528 8.95523 1.57343 8.95576 3.20547V6.88184C8.95589 7.43109 8.76024 7.94543 8.4468 8.38614L9.42825 9.36759C9.9368 8.69098 10.25 7.87647 10.25 7H11.75C11.75 8.31745 11.3069 9.53141 10.5617 10.501L11.4298 11.3692C11.7227 11.6621 11.7227 12.1369 11.4298 12.4298C11.1369 12.7227 10.6621 12.7227 10.3692 12.4298L9.501 11.5617C8.71848 12.1631 7.7768 12.5678 6.75 12.7015V13.5C6.75 13.9142 6.41421 14.25 6 14.25C5.58579 14.25 5.25 13.9142 5.25 13.5V12.7015C2.42874 12.334 0.25 9.92145 0.25 7H1.75C1.75 9.00277 3.38532 10.682 5.25 11.1317V9.6642C4.10998 9.25418 3.04469 8.16353 3.04428 6.88238V5.10494L0.46967 2.53033C0.176777 2.23744 0.176777 1.76256 0.46967 1.46967C0.762563 1.17678 1.23744 1.17678 1.53033 1.46967L3.05201 2.99135C3.16233 1.45936 4.44008 0.250467 6.00004 0.25ZM4.54428 6.60494V6.8819C4.54467 7.68578 5.19637 8.3376 6.00029 8.33786C6.08714 8.33783 6.17223 8.33019 6.25491 8.31557L4.54428 6.60494ZM7.38688 7.32622L4.54428 4.48362V3.20566C4.5447 2.40186 5.19624 1.75036 6.00004 1.75C6.80385 1.75039 7.45537 2.40215 7.45576 3.20596V6.88236C7.45581 7.03714 7.43166 7.18628 7.38688 7.32622ZM7.33167 9.39233C7.14348 9.50189 6.94787 9.5935 6.75 9.66461V11.1317C7.3164 10.9951 7.86163 10.7451 8.3463 10.407L7.33167 9.39233Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <button
                    v-if="callType === 'VIDEO'"
                    class="button-cam-element switch"
                    @click="toggleCamera"
                  >
                    <span class="icon icon-cam-active">
                      <svg
                        width="14"
                        height="13"
                        viewBox="0 0 14 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          :class="{
                        active: displayMyVideoStream,
                        inactive: !displayMyVideoStream,
                      }"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10 8.6732V8.70915C10 9.95203 8.99288 10.9591 7.75 10.9591H2.5C1.25712 10.9591 0.25 9.95203 0.25 8.70915V4.04248C0.25 2.7996 1.25712 1.79248 2.5 1.79248H7.75C8.99288 1.79248 10 2.7996 10 4.04248V4.07807L11.3098 3.02401C12.2916 2.23343 13.75 2.93294 13.75 4.1923V8.55897C13.75 9.81849 12.2912 10.518 11.3095 9.72699L10 8.6732ZM1.75 4.04248C1.75 3.62803 2.08555 3.29248 2.5 3.29248H7.75C8.16445 3.29248 8.5 3.62803 8.5 4.04248V5.64432C8.5 5.64462 8.5 5.64402 8.5 5.64432V7.1062C8.5 7.1064 8.5 7.106 8.5 7.1062V8.70915C8.5 9.1236 8.16445 9.45915 7.75 9.45915H2.5C2.08555 9.45915 1.75 9.1236 1.75 8.70915V4.04248ZM10 6.74781L12.25 8.5585V4.19277L10 6.00346V6.74781Z"
                          fill="white"
                        />
                        <path
                          :class="{
                        active: !displayMyVideoStream,
                        inactive: displayMyVideoStream,
                      }"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.58294 0.528126C1.32232 0.206181 0.850056 0.156469 0.528111 0.417091C0.206166 0.677714 0.156454 1.14998 0.417076 1.47192L1.08291 2.29442C0.574711 2.70691 0.250007 3.33662 0.250007 4.04234V8.70901C0.250007 9.95188 1.25713 10.959 2.50001 10.959H7.75001C7.86138 10.959 7.97087 10.9509 8.0779 10.9353L8.91708 11.9719C9.1777 12.2939 9.64996 12.3436 9.97191 12.083C10.2939 11.8223 10.3436 11.3501 10.0829 11.0281L9.42336 10.2133C9.78187 9.8148 10 9.28743 10 8.70901V8.67306L11.3095 9.72685C12.2913 10.5179 13.75 9.81834 13.75 8.55883V4.19216C13.75 2.93279 12.2916 2.23329 11.3098 3.02387L10 4.07794V4.04234C10 2.79946 8.99289 1.79234 7.75001 1.79234H2.60635L1.58294 0.528126ZM2.02674 3.46033C1.85783 3.59781 1.75001 3.8074 1.75001 4.04234V8.70901C1.75001 9.12346 2.08555 9.459 2.50001 9.459H6.88281L2.02674 3.46033ZM8.4414 9.00034L3.82064 3.29234H7.75001C8.16446 3.29234 8.50001 3.62789 8.50001 4.04234V8.70901C8.50001 8.81235 8.47914 8.91078 8.4414 9.00034ZM12.25 4.19263L10 6.0033V6.74769L12.25 8.55836V4.19263Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <button
                    class="button-share-element !mr-0"
                    style="background: white; border: 1px solid red"
                    @click="endCall"
                  >
                    <img
                      alt=""
                      src="/images/videocall/end-call.svg"
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import {useMainStore} from "~/store";

const counter = ref(0);
const activeDialog = ref('');
const dialogWidth = ref(320);
const direction = ref('INCOMING');
const callType = ref('');
const displayMyVideoStream = ref(false);
const displayOtherVideoStream = ref(false);
const isMuted = ref(false);

let mixedStream = null;

let ringTimeout = null;
const OUTGOING_TIMEOUT = 30000;
const INCOMING_TIMEOUT = 25000;

const mainStore = useMainStore();
const {showCallModal} = storeToRefs(mainStore);

// DOM references
const myVideoElm = ref(null);
const otherVideoElm = ref(null);

watch(showCallModal, async () => {
  if (useNuxtApp().$store.showCallModal === true) {
    direction.value = useNuxtApp().$store.callModalData.direction;
    callType.value = useNuxtApp().$store.callModalData.callType;
    // incoming call
    if (direction.value === 'INCOMING') {
      activeDialog.value = 'RING';
      dialogWidth.value = 320;
      startIncomingRing();
      ringTimeout = setTimeout(refuseCall, INCOMING_TIMEOUT);
    }
    // outgoing call
    if (direction.value === 'OUTGOING') {
      activeDialog.value = 'CALL';
      dialogWidth.value = "100%";
      counter.value++;
      startOutgoingCall();
    }
  } else {
    // close all medias
  }
})

onBeforeUnmount(() => {
  closeAllMedia();
});

const acceptCall = async () => {
  activeDialog.value = 'CALL';
  dialogWidth.value = "100%";
  counter.value++;

  displayMyVideoStream.value = true;

  callType.value = useNuxtApp().$store.callModalData.callType;
  mixedStream = await getMixedStream(callType.value);
  if (myVideoElm.value) {
    myVideoElm.value.srcObject = mixedStream;
    myVideoElm.value.play().then(() => {
      useNuxtApp().$chat.setupIncomingCall(mixedStream, onStream, onClose);
      stopIncomingRing();
      stopOutgoingRing();
    })
      .catch((err) => {console.log(err);});
  }
};
const refuseCall = () => {
  try {
    useNuxtApp().$chat.hangUp();
    closeAllMedia();
    resetStateForNextCall();
    useNuxtApp().$store.setCallModalData(null);
  } catch (err) {
    console.log(err);
  }
};

const startOutgoingCall = async () => {
  try {
    callType.value = useNuxtApp().$store.callModalData.callType;
    mixedStream = await getMixedStream(callType.value);
    displayMyVideoStream.value = true;
    myVideoElm.value.srcObject = mixedStream;
    myVideoElm.value.play().then(() => {
      useNuxtApp().$chat.setupOutgoingCall(
        useNuxtApp().$store.callModalData.peerUser,
        callType.value,
        mixedStream,
        onStream,
        onClose);
      ringTimeout = setTimeout(cancelOutgoingCall, OUTGOING_TIMEOUT);
      startOutgoingRing();
    });
  } catch (err) {
    console.log(err);
  }
};

const onStream = (stream) => {
  clearTimeout(ringTimeout);
  stopIncomingRing();
  stopOutgoingRing();
  if (!displayOtherVideoStream.value) {
    useNuxtApp().$chat.sendEvent('CALL_CONNECT', useNuxtApp().$store.callModalData.peerUser.uuid);
    displayOtherVideoStream.value = true;
    otherVideoElm.value.srcObject = stream;
    otherVideoElm.value.play();
  }
}

const onClose = () => {
  try {
    useNuxtApp().$chat.sendEvent('CALL_DISCONNECT', useNuxtApp().$store.callModalData.peerUser.uuid);
    closeAllMedia();
    useNuxtApp().$chat.hangUp();
    resetStateForNextCall();
  } catch (err) {
    console.log(err);
  } finally {
    useNuxtApp().$store.setCallModalData(null);
  }
}
const endCall = () => {
  useNuxtApp().$chat.sendEvent('CALL_HANGUP', useNuxtApp().$store.callModalData.peerUser.uuid);
  closeAllMedia();
  useNuxtApp().$chat.hangUp();
  resetStateForNextCall();
  useNuxtApp().$store.setCallModalData(null);
};

const cancelOutgoingCall = () => {
  closeAllMedia();
  useNuxtApp().$chat.cancelOutgoingRingingCalls();
  useNuxtApp().$chat.sendMissedCallEvent();
  resetStateForNextCall();
  useNuxtApp().$store.setCallModalData(null);
}

const getMixedStream = async (callType) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: callType === 'VIDEO'
    });
    return stream;
  } catch (err) {
    console.log(err.track);
    return new MediaStream();
  }
}

const startIncomingRing = () => {
  if (document.getElementById("incomingRing")) {
    document.getElementById("incomingRing").currentTime = 0;
    document.getElementById("incomingRing").play();
  }
}
const stopIncomingRing = () => {
  if (document.getElementById("incomingRing"))
    document.getElementById("incomingRing").pause();
}
const startOutgoingRing = () => {
  if (document.getElementById("outgoingRing")) {
    document.getElementById("outgoingRing").currentTime = 0;
    document.getElementById("outgoingRing").play();
  }
}
const stopOutgoingRing = () => {
  if (document.getElementById("outgoingRing"))
    document.getElementById("outgoingRing").pause();
}

const closeAllMedia = () => {
  stopOutgoingRing();
  stopIncomingRing();
  try {
    if (myVideoElm.value?.srcObject) {
      myVideoElm.value.srcObject.getTracks().forEach(function (track) {
          if (track.readyState === "live") {
            track.stop();
          }
        });
    }
    if (otherVideoElm.value?.srcObject) {
      otherVideoElm.value.srcObject.getTracks().forEach(function (track) {
          if (track.readyState === "live") {
            track.stop();
          }
        });
    }
  } catch (err) {
    console.log(err);
  }
};

const resetStateForNextCall = () => {
  if (mixedStream) {
    mixedStream.getTracks().forEach(function (track) {
      if (track.readyState === "live") {
        track.stop();
      }
    });
  }
  mixedStream = null;
  displayMyVideoStream.value = false;
  displayOtherVideoStream.value = false;
  isMuted.value = false;

  if (ringTimeout)
    clearTimeout(ringTimeout);
}

const toggleMute = () => {
  if (mixedStream) {
    const audioTracks = mixedStream.getAudioTracks();
    if (audioTracks.length > 0) {
      const track = audioTracks[0];
      track.enabled = !track.enabled; // Toggle the track's enabled state
      isMuted.value = !track.enabled; // Toggle the isMuted state
    }
  }
};

const toggleCamera = () => {
  if (mixedStream) {
    const videoTracks = mixedStream.getVideoTracks();
    if (videoTracks.length > 0) {
      const track = videoTracks[0];
      track.enabled = !track.enabled; // Toggle the track's enabled state
      displayMyVideoStream.value = track.enabled;
    }
  }
};
</script>

<style scoped>
.fullScreen {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100%;
}

.call-modal-dialog {
  width: 100%;
}

.fullscreen-video {
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
  /* padding: 0.5rem; */
  background: #1d1d1d;
}

.fullScreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.fullScreen .section-container {
  border: 0 !important;
  border-radius: 0 !important;
}
.fullScreen .section-container #open_screen {
  border-radius: 0 !important;
  border: 0 !important;
}

.section-container {
  border: 2px solid #1d1d1d;
  border-radius: 2px;
  box-shadow: #000000 0 3px 8px;
  background: #1d1d1d !important;
}

.fullscreen-media-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #323232;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.fullscreen-media-container.video {
  position: relative;
}

.fullscreen-media-container.video video {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.fullscreen-media-container .overlay-content-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 0 15px 30px;
  display: flex;
  justify-content: space-between;
}

.fullscreen-media-container .partner-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 13px;
  right: 0;
  margin-right: 2rem;
  padding-top: 1rem;
}

.fullscreen-media-container .partner-text-container button {
  margin-right: 15px;
  pointer-events: none;
}

.fullscreen-media-container .partner-text-container .name {
  display: block;
}

.fullscreen-media-container .cam-container {
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  max-width: 320px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
  transform-origin: 100% 100%;
}

.fullscreen-media-container .cam-container .content {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fullscreen-media-container .cam-container .content .name {
  display: block;
}

.fullscreen-media-container .cam-container .video video {
  width: 100%;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.fullscreen-media-container .cam-container .video::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity cubic-bezier(0.445, 0.05, 0.55, 0.95);
  background-color: #272727;
}

.fullscreen-media-container .cam-container.disabled {
  transform: translate(400px, 0);
  opacity: 0.1;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.05);
}

.fullscreen-media-container .cam-container.disabled .video {
  opacity: 0;
}

.fullscreen-media-container .cam-container.disabled .video::after {
  opacity: 1;
}

.fullscreen-media-container .ui-container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.fullscreen-media-container .ui-container .navigation-controls-container {
  padding: 12px 15px;
  border-radius: 120px;
  width: auto;
  display: flex;
  flex-direction: row;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(67px);
  background-color: rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
}

@supports not (
  (-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))
) {
  .fullscreen-media-container .ui-container .navigation-controls-container {
    background-color: rgba(0, 0, 0, 0.35);
  }
}

span.name {
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 1rem;
}

button {
  all: unset;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

button.size-s {
  width: 28px;
  height: 28px;
}

button[class*="-cam-element"],
button[class*="-mic-element"],
button[class*="-share-element"] {
  margin-right: 15px;
  border-radius: 70px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  transform: translateZ(0);
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

button[class*="-cam-element"].active,
button[class*="-mic-element"].active,
button[class*="-share-element"].active {
  background-color: #f8175a;
}

button[class*="-cam-element"]:hover,
button[class*="-mic-element"]:hover,
button[class*="-share-element"]:hover {
  transform: scale(1.08);
}

button[class*="-share-element"].active {
  background-color: #034ff4;
}

button[class*="-settings"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button[class*="-settings"] span {
  width: 100%;
  max-width: 18px;
  height: 2px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}

button[class*="-settings"] span::before,
button[class*="-settings"] span::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #fff;
  border-radius: 10px;
  transform-origin: 0 100%;
  transition: all 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

button[class*="-settings"] span::before {
  top: -6px;
}

button[class*="-settings"] span::after {
  bottom: -6px;
}

button[class*="-settings"]:hover span::before {
  transform: scaleX(0.5);
}

button span.icon {
  display: flex;
}

button span.icon svg [class*="inactive"],
button span.icon span[class*="hide"] {
  visibility: hidden;
}

button span.icon.active svg [class*="inactive"],
button span.icon.active span[class*="hide"] {
  visibility: visible;
}

.flex-col {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .col {
    padding: 0 20px;
  }

  .card-video {
    height: 30rem;
  }

  span.name {
    font-size: 10px;
    padding: 1rem;
  }
  .fullscreen-media-container .partner-text-container {
    padding-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .fullscreen-media-container .cam-container .video {
    position: relative;
    padding-bottom: 56.25%;
  }
  .col {
    padding: 0 20px;
  }

}

html {
  min-height: 100%;
  overflow-y: scroll;
}

.button_mic {
  width: 28px;
  height: 28px;
  margin-left: 10px;
  border-radius: 70px;
  color: white;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  transform: translateZ(0);
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  background: rgb(255 51 102);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figure,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
}

ul li,
ol li {
  padding: 0;
}

img {
  height: auto;
  max-width: 100%;
  display: block;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  width: 100%;
  height: 100%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #080808;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 320px;
  font-size: 18px;
  color: #fff;
}

section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .cam-container {
    height: 160px;
    margin-left: auto !important;
    margin-right: 0 !important;
    max-width: 100px !important;
  }
  .fullscreen-media-container .overlay-content-container[data-v-da25beb8] {
    padding: 0 20px 10px;
  }
  .fullscreen-media-container .cam-container .video[data-v-da25beb8] {
    padding-bottom: 0;
    height: 100%;
  }
  .partner-text-container {
    right: unset;
    margin-right: unset;
    left: 0;
    margin-left: 1rem;
  }
}

.call-animation {
  background: rgb(165 159 159);
  width: 95px;
  height: 95px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  animation: play 1.5s ease infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.pulse img {
  width: 95px;
  height: 95px;
  object-fit: cover;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba(165, 159, 159, 0.4);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(165, 159, 159, 0.4),
      0 0 0 20px rgba(165, 159, 159, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(165, 159, 159, 0.4),
      0 0 0 30px rgba(165, 159, 159, 0.2);
  }
}
@media (min-width: 768px) {
  #card-title {
    padding: 2rem 1rem 0px !important;
  }
}
</style>
