<template>
  <v-dialog
    :model-value="$store.showConfirmation"
    :persistent="true"
    maxWidth="400"
    contentClass="alert-modal"
  >
    <v-card :key="$store.counter" style="border-top: 8px solid #00acdf">
      <v-card-title class="text-h5">
        {{ $t($store.confirmationAlert.title) }}
      </v-card-title>
      <v-card-text class="p-0">
        <v-alert
          :type="$store.confirmationAlert.alertType"
          color="white"
          variant="flat"
          :prominent="true"
        >
          <p class="text-sm mb-0">
            {{ $t($store.confirmationAlert.message) }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions class="pb-4 mr-3">
        <v-spacer />
        <v-btn v-if="$store.confirmationAlert.btn_ko?.length > 1" variant="flat" @click="doCancel">
          {{ $t($store.confirmationAlert.btn_ko) }}
        </v-btn>
        <v-btn
          variant="flat"
          class="text-white"
          style="background: #00acdf"
          @click="doOK"
        >
          {{ $t($store.confirmationAlert.btn_ok) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const doOK = () => {
  if (useNuxtApp().$store.confirmationAlert.okFn) {
    useNuxtApp().$store.confirmationAlert.okFn();
  }
  useNuxtApp().$store.setConfirmation(false);
};
const doCancel = () => {
  if (useNuxtApp().$store.confirmationAlert.koFn) {
    useNuxtApp().$store.confirmationAlert.koFn();
  }
  useNuxtApp().$store.setConfirmation(false);
};
</script>

<style scoped>
.v-alert__border {
  border: 0;
}
</style>
